
h1{
  text-align: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}
 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

}

.cont-servi-principal  {
  width: 100%;
  margin: 0px;
  
}
.eslogan1su1 {
  text-align: center;
}

.titulosu1 {
  margin-top: 10px;
   text-align: center;
   color: #070707;
   font-size: 20px !important;
   font-weight: bold;
}

.cont-buscar1, .form-groupef1 {
  text-align: center;
}
.identificacionr {
  text-align: center;
}
.identificacionr2 {
  text-align: center;
  background-color: rgb(205, 239, 243);
  cursor: pointer;
}

.subirtexto1 {
  text-align: center;
  
}
.groupef{
  color: #fff;
  width: 100%;
  height: 280px;
}

.cerrar {
  color: red;
  font-size: 15px;
  margin-left: 60px;
  font-weight: bold;
  cursor: pointer;

}
.preview {
  margin-top: 2px !important;
}

.cont-foto1 {
  margin-left: 20px;
  margin-bottom: 10px;
}
.cont-spinner{
  border: 15px;
  position:fixed;
  top: 35%;
  left: 20%;
  right: 0;
  margin: 0 auto;
  width: 25%;
  height: 385px;
  justify-content: center;
  z-index: 150;

}
.spinner{ 
  display: block;
  margin: 0 auto;
  border-color: red;
}
.titulo-notificacion {
  margin-bottom: 0px !important;
}