body   {
 

}
.MuiButton-textPrimary-212{
    color: rgb(22, 19, 228) !important;
}

.title > * {
    color: rgb(22, 19, 228) !important;
  }

  .descripcion {
    background: transparent;
    color: rgb(22, 19, 228) !important;
  }