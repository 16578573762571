h1{
    text-align: center;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
  }
   
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}
.cont-footer-princ {
 left: 30px !important; 
}

.contenedor-cxc {
  width: 100%;
  height: 550px;
  background-color: #e7f3ce
}

  .contenedor-titulocxc {
    display: contents;
    height:200px;
  }

  .titulo-cxc {
    line-height: 30px;
    background-color: #028d7b;
    font-size: 20px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .titulo-reprecxc {
    margin-left: 20px;
    line-height: 10px;
    font-size: 15px;
    text-align: center;
    color: #028d7b;
    font-weight: bold;

  }

  .labelcedulac  {
    font-size: 20px;
    color: #050505;
    font-weight: bold;
  }

  .contenedor-cedula, .xcontenedorcedula1, .contenedorcedula2, .contenedorcedula3, .cont-radio {
    text-align: center;
    display: inline-block;
  }

  .contenedorcedula1 {
    margin-top: 0px !important;
}
.contenedorcedula3 {
  margin-top: 0px !important;
  margin-left: 30px;
  width: 500px;
}
.form-group {
  margin-bottom: 0 !important;
}

  .cont-listmedi {
    color: rgb(7, 5, 5);
    top: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
  }
  

  .listcxc {
    background-color: #fff;
    height: 300px;
    width: auto;
    overflow: auto;
  }

  .tablacxc{
      color: #fff;
      background-color: #fff;
      width: 100%;
      border: 1px solid #7ac5cd;
      font-size: 16px;
      border-collapse: collapse;
      /* margin: 0 0 1em 0; */
      caption-side: top;
      border-collapse: collapse;  
      height: 20px;
  }
  .ititucxc1, .ititucxc2,.ititucxc3  {
    background-color: #2bbbad ;
    color: #fff;
    font-weight: bold;
    height: 40px;
  }
  .ititucxc1 {
    text-align: center;
    width: 600px;
  }
  .ititucxc2 {
    text-align: center;
    width: 100px;
  }
  .ititucxc3 {
    text-align: center;
    width: 70px !important;
  }

  .cont-datoscxc {
    height: 20px;
  }

  .datoscxc, .datoscxc1{
    color: black;
    font-weight: bold;
  }
  .datoscxc1 {
    text-align: right;
  }
  .cont-totalescxc {
    color: #fff;
    font-weight: bold;
    background-color: #2bbbad;
  }
  .rtotalescxc {
    text-align: left;
  }

  .totalescxc1, .totalescxc2, .totalescxc3 {
    text-align: right;
    font-size: 20px;
  }
.totalescxc2{
  color: red;
  font-weight: bold;
  width: 70px !important;
}
.totalescxc3{
  color: green;
  font-weight: bold;
}
.contenedor-pago{
  height: 400px !important;
}

.flotador{
  margin-top: 50px;
  position: absolute;
  height: 70%;
  width: 90%;
  align-Content: center;
  justify-Content: center;
  z-index:10;
  background-color:  #2bbbad;
}

.flotador1{
  align-Self: flex-start;
  width: 100%;
}
.flotador1x{
  margin-top: 50px;
  position: absolute;
  height: 80%;
  width: 90%;
  align-Content: center;
  justify-Content: center;
  z-index:10;
  background-color:  #2bbbad;
}

.cont-check {
  text-align: center;
}
#impuesto{
  height: 30px;
}
.contenedorimpuesto {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
}
.contenido1{
  margin-left: 20px;
}
.identificacionr2 {
  margin-top: 8px !important;
  height: 45px;
}

.subirtexto1 {
  margin: 0 !important;
}
.botonOf {
  color: rgba(182,184,214,1);
  background: transparent;
  outline: none;
  border: solid 1px rgb(18, 149, 167);;
  font-size: 16px;
  padding: 5px 5px; 
  border-radius: 10px; 
  margin-right: 20px; 
}    

.imagen{
  height: 70px;
  width: 100px;
  border: none;
}
.totalC{
  margin-top: 10px;
  font-size: 25px;
  color: #050505;
}