.icono-izquierdamo{
    /* margin-left: 20px; */
    color: red;
    font-size: 30px;
    cursor: pointer;
}

.groupevDOA {
    background-color: #6aaeec;
    height: 100px;
}

.cont-botones {
    text-align: center;
}

.botones-funcion{
    margin-top: 15px;
    margin-right: 5px;
    background-color: lightseagreen;
    font-size: 18px;

}
