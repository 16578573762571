h1 {
  text-align: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.cont-footer-princ {
  left: 30px !important;
}

.contenedor-pagoM {
  width: 100%;
  height: 550px;
  background-color: #e7f3ce;
}

.contenedor-cedula {
  width: 100%;
  margin: 0px;
}
.contenedor-titulo {
  display: contents;
  height: 200px;
}
.titulo-pago {
  line-height: 50px;
  background-color: #028d7b;
  font-size: 15px;
  text-align: center;
  color: #050505;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 15px;
}

.labelcedulac {
  font-size: 15px !important;
  color: #050505;
  font-weight: bold;
}
.contenedor-cedula,
.xcontenedorcedula1,
.contenedorcedula2,
.cont-radio {
  text-align: center;
  display: inline-block;
}

.contenedorcedula1M {
  text-align: center;
  display: inline-block;
  margin-top: 10%;
}
.contenedorcedula1Mx{
  text-align: center;
  display: inline-block;
  margin-top: 10%;
  font-size: 25px;
}

.xcontenedorcedula1 {
  margin-top: 50px;
}

.labelcedulac1 {
  font-size: 18px;
  color: #050505;
  font-weight: bold;
  margin-right: 20px;
}
.opnivel2 {
  font-size: 20px;
  margin-left: 20px;
}

.boton,
.botonN {
  color: #070707;
  background-color: #fff;
  outline: none;
  border: solid 2px#C8C8C8;
  font-size: 16px;
  padding: 5px 5px;
  border-radius: 10px;
  font-weight: bold;
  margin-bottom: 5%;
  margin-right: 15px;
}
.boton,
.notonN:hover {
  color: #fff;
  background-color: rgb(35, 80, 69);
}
.renglon {
  height: 30px;
}
.odd {
  background-color: #11a794;
}

.tabla {
  color: #fff;
  background-color: #7ac5cd;
  width: 100%;
  border: 1px solid #7ac5cd;
  text-align: left;
  border-collapse: collapse;
  margin: 0 0 1em 0;
  caption-side: top;
}
.fechas {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

#codigo3 {
  width: 150px;
  height: 35px;
  margin-right: 25px;
  font-size: 18px;
}

#codigo4 {
  width: 150px;
  height: 35px;
  font-size: 20px;
}

.titucxc1M {
  text-align: center;
  width: 40px;
  background-color: #2bbbad;
}
.titucxc2M {
  text-align: center;
  width: 120px;
  background-color: #2bbbad;
}
.titucxc3M {
  text-align: center;
  width: 60px;
  background-color: #2bbbad;
}