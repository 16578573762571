.cerrarme4{
    background-color: #6aaeec;
    background-image: url('../../imagenes/editor.png');
    background-size: 20px;
    background-repeat: no-repeat; 
    width: 25px;
    height: 25px;
    background-position:center;
    font-size: 14px;
    padding: 1px 1px;
    border-radius: 5px;
    display:inline-block;
    margin-top: 2px;
  }
  .cerrarme8{
    background-color: #6aaeec;
    background-image: url('../../imagenes/evaluar.png');
    background-size: 30px;
    background-repeat: no-repeat; 
    width: 25px;
    height: 25px;
    background-position:center;
    font-size: 14px;
    padding: 1px 1px;
    border-radius: 5px;
    display:inline-block;
    cursor: pointer;
    margin-top: 2px;
  }

  .cerrarme7{
    background-color: #6aaeec;
    background-image: url('../../imagenes/lupa.png');
    background-size: 30px;
    background-repeat: no-repeat; 
    width: 25px;
    height: 25px;
    background-position:center;
    font-size: 14px;
    padding: 1px 1px;
    border-radius: 5px;
    display:inline-block;
    cursor: pointer;
    margin-top: 2px;
    margin-right: 25px;
  }
  .cerrarme6{
    background-color: #6aaeec;
    background-image: url('../../imagenes/excel.png');
    background-size: 20px;
    background-repeat: no-repeat; 
    width: 25px;
    height: 25px;
    background-position:center;
    font-size: 14px;
    padding: 1px 1px;
    border-radius: 5px;
    display:inline-block;
    cursor: pointer;
    margin-top: 2px;
    margin-right: 25px;
  }
  .opcionno{
      margin-top: 10px;
      width: 100px;
  }
  #ordendellegada{
      height: 25px;
      width: 50px;
      margin: 0;
      padding: 0;
      text-align: left;    
  }
  .cont-fechano {
        margin-top: 10px;
        margin: 0;
}
  #fechaesperano{
      height: 25px;
      margin:0;
  }
  .cont-motivo-no{
      text-align: left;
  }

  .cont-listmedix {
        color: rgb(167, 167, 167);
        top: 40px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 80% !important;
        justify-content: center;
  }
  

  .listcxcx {
    background-color: #e7f3ce;
    height: 240px;
    width: auto;
    overflow: auto;
  }

  .tablacxcx{
    color: #fff;
    background-color: #fff;
    width: 100%;
    border: 1px solid #7ac5cd;
    font-size: 16px;
    border-collapse: collapse;
    /* margin: 0 0 1em 0; */
    caption-side: top;
    border-collapse: collapse;  
    height: 20px;
}
  .xcontenedorcedula1x {
    text-align: center;
    display: inline-block;
    margin-top: 10px !important;

  }
  .titucxc3x{
      width: 5px !important;
      text-align: center !important;
  }
.totalescxcx {
    cursor: context-menu !important;
    text-align: left !important;
    font-size: 12px !important;
}
.contenedorcedula1x {
    width: 700px !important;
}
#idmateria, #idfechaPlani, #idprofesor2{
  font-size: 12px;
}
#fechaDesde, #fechaHasta{
  font-size: 10px;
}
.contenedorcedula1f{
  margin-bottom: 5px;
}
.botones{
  margin-top: 10px;
  text-align: center;  
}
.listadopc{
  background-color: #028d7b;
  color: #fff;
}
.desde, .hasta{
  margin-top: 15px;
}


.cont-listadopc {
  color: rgb(7, 5, 5);
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
}

.listadopc {
  height: 380px;
  width: 100%;
  font-size: 12px;
  width: auto;
  overflow: auto;
}

.tabla2pc {
  color: #fff;
  background-color: #7ac5cd;
  width: 100%;
  border: 1px solid #7ac5cd;
  font-size: 15px;
  text-align: center;
  border-collapse: collapse;
  margin: 0 0 1em 0;
  caption-side: top;
}

.cuerpo{
    cursor: pointer;
}

.cuerpo1{
  cursor: pointer;
}

.tituevo{
  color: #fff;
  background-color: #028d7b;
  text-align: left;
  padding: 10px;

}

.titucxc4{
  width: 50px !important;
  text-align: center !important;
  background-color: #2bbbad;
}

.opcionev{
  color: #fff;
  text-align: center;
  font-size: 20px;
}

.odd{
  background-color: #11a794;
}


.flotadorT {
  margin-top: 1px;
  position: absolute;
  height: 90%;
  width: 98%;
  align-content: center;
  justify-content: center;
  z-index: 10;
  background-color: #2bbbad;
}

.flotadorTE {
  margin-top: 30px;
  position: absolute;
  height: 85%;
  width: 98%;
  align-content: center;
  justify-content: center;
  z-index: 10;
  background-color: #2bbbad;
}

