
h1{
  text-align: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}
 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

}

.cont-servi-principal  {
  width: 100%;
  margin: 0px;
  
}
.eslogan1su1 {
  text-align: center;
}

.titulosu1 {
  margin-top: 20px;
   text-align: center;
   color: #070707;
   font-size: 40px;
   font-weight: bold;
}

.cont-buscar1, .form-groupef1 {
  text-align: center;
}
.identificacionr {
  text-align: center;
}
.identificacionr2 {
  text-align: center;
  background-color: rgb(205, 239, 243);
}

.subirtexto1 {
  text-align: center;
}
.groupef{
  color: #fff;
  width: 100%;
  height: 280px;
}

.cont-foto {
  width: 80px;
  height: 110px;
  margin-left: 20px;
  margin-top: 20px;
  background-color: rgb(205, 239, 243);
}
.cerrar {
  color: red;
  font-size: 15px;
  margin-left: 60px;
  font-weight: bold;
  cursor: pointer;

}
.preview {
  margin-top: 20px;
}
.fotos {
  width: 80px;
  height: 100px;
}

.cont-spinner{
  border: 15px;
  position:fixed;
  top: 35%;
  left: 20%;
  right: 0;
  margin: 0 auto;
  width: 25%;
  height: 385px;
  justify-content: center;
  z-index: 150;

}
.spinner{ 
  display: block;
  margin: 0 auto;
  border-color: red;
}

.flotador{
  margin-top: 50px;
  position: absolute;
  height: 70%;
  width: 90%;
  align-Content: center;
  justify-Content: center;
  z-index:10;
  background-color:  #2bbbad;
}

.flotador1{
  align-Self: flex-start;
  width: 100%;
}
.cont-check {
  text-align: center;
}

.cont-opcion22c {
  margin-bottom: 15px;
  margin-left: 50px ;
  text-align: center;
  color: #fff;
  font-size: 15px;
  margin-right: 10px;

}

.opcion2c{
  color: rgb(14, 13, 13);
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  width: 170px;
}

#cedulaE, #cedulaP, #actaN, #controlV, #fotosP, #certificadoN, #solvencia, #cartaConducta, #cartaSalud, #pruebaA, #pruebaP, #fotosE {
  font-size: 4px;
  text-decoration: none;
  width: 100%;
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  
}