h1{
    text-align: center;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
  }
   
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  
}
.cont-footer-princ {
 left: 30px !important; 
}

.contenedor-pago {
  width: 100%;
  height: 550px !important;
  background-color: #e7f3ce
}

.contenedor-cedula  {
    width: 100%;
    margin: 0px;
    
  }
  .contenedor-titulo {
    display: contents;
    height:200px;
  }
  .titulo-pago {
    line-height: 50px;
    background-color: #028d7b;
    font-size: 15px;
    text-align: center;
    color: #050505;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 15px;


  }

  .labelcedulac  {
    font-size: 18px;
    color: #050505;
    font-weight: bold;
    
  }
  .contenedor-cedula, .contenedorcedula1, .contenedorcedula2, .cont-radio {
    text-align: center;
      display: inline-block;
  }
  
  .contenedorcedula1{
      margin-top: 20px;
  }
  .labelcedulac1  {
    font-size: 18px;
    color: #050505;
    font-weight: bold;
    margin-right: 20px;
    
  }
  .opnivel2 {
    font-size: 20px;
    margin-left: 20px;
  }

  .boton {
    color: #070707;
    background-color: #fff;
    outline: none;
    border: solid 2px#C8C8C8;
    font-size: 16px;
    padding: 5px 5px;
    border-radius: 10px; 
    font-weight: bold;
    margin-bottom: 5%;

  }
.boton:hover {
  color:  #fff;
  background-color: rgb(35, 80, 69)

}
.transfe{
  margin-right: 15px;
}