h1{
    text-align: center;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
  }
   
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  
}
.cont-footer-princ {
 left: 30px !important; 
}

.contenedor-pago1 {
  width: 100%;
  height: 550px;
  background-color: #e7f3ce
}

.contenedor-cedula  {
    width: 100%;
    margin: 0px;
    
  }
  .contenedor-titulo {
    display: contents;
    height:200px;
  }
  .titulo-pago {
    line-height: 50px;
    background-color: #028d7b;
    font-size: 15px;
    text-align: center;
    color: #050505;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 15px;


  }

  .labelcedulac  {
    font-size: 18px;
    color: #050505;
    font-weight: bold;
    
  }
  .contenedor-cedula, .contenedorcedula1, .contenedorcedula1fr, .cont-radio {
    text-align: center;
      display: inline-block;
  }
  
  .contenedorcedula1fr{
      margin-right: 50px;
  }
  .labelcedulac1  {
    font-size: 18px;
    color: #050505;
    font-weight: bold;
    margin-right: 20px;
    
  }
  .opnivel2 {
    font-size: 20px;
    margin-left: 20px;
  }

  .boton {
    color: #070707;
    background-color: #fff;
    outline: none;
    border: solid 2px#C8C8C8;
    font-size: 12px !important;
    padding: 5px 5px;
    border-radius: 10px; 
    font-weight: bold;
    margin-bottom: 5%;

  }
.boton:hover {
  color:  #fff;
  background-color: rgb(35, 80, 69)

}
.titucxc11, .titucxc22, .titucxc33, .titucxc44  {
  background-color: #2bbbad ;
  color: #fff;
  font-weight: bold;
  height: 10px;
}
.titucxc11 {
  text-align: center;
  width: 10px;
  font-size: 15px;
}
.titucxc22 {
  text-align: center;
  width: 40px;
  font-size: 15px;
}
.titucxc33 {
  text-align: center;
  width: 200px;
  font-size: 15px;
}
.totalescxc11, .totalescxc22, .totalescxc33 {
  text-align: left;
  font-size: 12px;
  width: 40px;
}
.totalescxc44 {
  text-align: right;
  font-size: 12px;
}
.totalescxc2{
color: red;
font-weight: bold;
}

.cont-listadopc {
  color: rgb(7, 5, 5);
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
}

.listadopcr {
  height: 380px;
  width: 100%;
  font-size: 12px;
  background-color: #e7f3ce;
  color:#e7f3ce;;
  width: auto;
  overflow: auto;
}
.tabla2pcr {
  color: #fff;
  background-color: #ffffff;
  width: 100%;
  border: 2px solid #070707;
  font-size: 15px;
  text-align: left;
  color: #070707;
  border-collapse: collapse;
  margin: 0 0 1em 0;
  caption-side: top;
}

.cuerpo{
    cursor: pointer;
}

.cuerpo1{
  cursor: pointer;
}

.tituevo{
  color: #fff;
  background-color: #028d7b;
  text-align: left;
  padding: 10px;

}

.opcionev{
  color: #fff;
  text-align: center;
  font-size: 20px;
}

.oddr{
  background-color: #ffffff;
  border: 2px solid #070707;
}

.itemtablaR{
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  background-color: #ffffff;
  color: rgb(42, 28, 240);
  border: black solid;
  margin: 15px;
  padding: 15px;
}
.contenedorR{
   border:1px solid Red; 
}

#idmateria, #idfechaPlani, #idprofesor, #tipoR, #fecha1, #fecha2 {
  font-size: 12px !important;
}

.contenedorcedula1fx{
  font-size: 12px !important;
  display: inline-block;
}