h1{
    text-align: center;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
  }
   
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}
.cont-footer-princ {
 left: 30px !important; 
}

.contenedor-cxc {
  width: 100%;
  height: 550px;
  background-color: #e7f3ce
}

  .contenedor-titulocxc {
    display: contents;
    height:200px;
  }

  .titulo-cxc {
    line-height: 30px;
    background-color: #028d7b;
    font-size: 20px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .titulo-reprecxc {
    margin-left: 20px;
    line-height: 10px;
    font-size: 15px;
    text-align: center;
    color: #028d7b;
    font-weight: bold;

  }

  .labelcedulac  {
    font-size: 20px;
    color: #050505;
    font-weight: bold;
  }

  .contenedor-cedula, .xcontenedorcedula1, .contenedorcedula2, .cont-radio {
    text-align: center;
      display: inline-block;
  }

  .cont-listmedi {
    color: rgb(7, 5, 5);
    top: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
  }
  

  .listcxc {
    background-color: #fff;
    height: 340px;
    width: auto;
    overflow: auto;
  }

  .tablacxc{
      color: #fff;
      background-color: #fff;
      width: 100%;
      border: 1px solid #7ac5cd;
      font-size: 16px;
      border-collapse: collapse;
      /* margin: 0 0 1em 0; */
      caption-side: top;
      border-collapse: collapse;  
      height: 20px;
  }
  .titucxc1, .titucxc2,.titucxc3  {
    background-color: #2bbbad ;
    color: #fff;
    font-weight: bold;
    height: 40px;
  }
  .titucxc1 {
    text-align: center;
    width: 130px;
  }
  .titucxc2 {
    text-align: center;
    width: 500px;
  }
  .titucxc3 {
    text-align: center;
    width: 140px;
  }

  .cont-datoscxc {
    height: 20px;
  }

  .datoscxc, .datoscxc1{
    color: black;
    font-weight: bold;
  }
  .datoscxc1 {
    text-align: right;
  }
  .cont-totalescxc {
    color: #fff;
    font-weight: bold;
    background-color: #2bbbad;
  }
  .totalescxc {
    text-align: center;
  }

  .totalescxc1, .totalescxc2, .totalescxc3 {
    text-align: right;
    font-size: 20px;
  }
.totalescxc2{
  color: red;
  font-weight: bold;
}
.totalescxc3{
  color: green;
  font-weight: bold;
}