*{
    font-family: sans-serif;
    box-sizing: border-box;
  }
form {
    
    /* margin: auto; */
    margin-left: 20%;
    width: 100%;
    max-width: 400px;
    min-height: 60vh;
    display: flex;
    justify-content: center;
     align-items: center ;
}

.formulario {
    width: 100%;
    padding: 30px;
    border: 1px solid #028d7b;
    background-color: #11a794;
  -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
  -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
  box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
  margin-bottom: 15px; 
}
h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
}
input, .aceptar, .eliminar {
    display: block;
    padding: 5px;
    width: 100%;
    margin: 10px 0;
    font-size: 15px;
}

.aceptar, .eliminar {
    color: #028d7b;
    background-color: #fff;
    outline: none;
    border: solid 2px aquamarine;
    font-size: 16px;
    cursor: pointer;
    border-radius: 20px;

    /* background: linear-gradient(rgb(29, 43, 241), rgb(7, 112, 250));
    border: none;
    color: #fff;
    opacity: 0.5;
    cursor: pointer;
    border-radius: 20px;
    margin-bottom: 0; */
}

.aceptar, .eliminar:hover {
    color:  #fff;
    background-color: rgb(35, 80, 69)

}